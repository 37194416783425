import { Box, SegmentedControl } from "@mantine/core";
import { useCallback } from "react";
import type { Period } from "../../../types";

interface IPeriodSelector {
	value: Period;
	onChange: (period: Period) => void;
}

export const PeriodSelector = ({ value, onChange }: IPeriodSelector) => {
	const onClick = useCallback(
		(value: string): void => {
			onChange(value as Period);
		},
		[onChange]
	);

	return (
		<SegmentedControl
			visibleFrom="sm"
			value={value}
			onChange={(value: string) => onClick(value)}
			data={[
				{
					value: "week",
					label: <Box>Vecka</Box>,
				},
				{
					value: "month",
					label: <Box>Månad</Box>,
				},
			]}
		/>
	);
};
