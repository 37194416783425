import { ActionIcon, Button, Select, Table } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
	deleteAssignProjects,
	isBillingType,
	postAssignProjects,
} from "../../api/assigned_projects";
import { getProjectsFromAssignedProjects } from "../../api/projects";
import { getUser } from "../../api/users";
import { CurrencyInput } from "../../components/currency-input";
import { ProjectSelector } from "../../components/project-selector/project-selector";
import type {
	AssignedProject,
	InputAssignedProjectsPost,
	Project,
	User,
} from "../../generated";
import { IconPencil } from "@tabler/icons-react";
import { AssignedProjectRow } from "./assigned-project-row";

interface IProjectList {
	assignedProjects?: Array<AssignedProject>;
	userId: string;
	onAssignmentAdded?: () => void;
}

const getProjectForAssignedProject = (
	ap: AssignedProject,
	projects: Project[],
): Project | undefined => projects.find(p => p.id === ap.project_id);

export const ProjectList = ({
	assignedProjects,
	userId,
	onAssignmentAdded,
}: IProjectList) => {
	const [newAssignment, setNewAssignment] = useState<
		Omit<
			InputAssignedProjectsPost,
			"start_date" | "end_date" | "project_id"
		> & {
			project_id: string | undefined;
			start_date: Date;
			end_date: Date | null;
		}
	>({
		project_id: undefined,
		user_id: userId,
		start_date: new Date(),
		note: null,
		end_date: null,
		customer_billing_type: "hourly",
		customer_currency: "SEK",
		customer_rate: 1000,
	});

	const [projects, setProjects] = useState<Project[]>([]);
	const [user, setUser] = useState<User>();

	useEffect(() => {
		if (assignedProjects)
			getProjectsFromAssignedProjects(assignedProjects).then(setProjects);
	}, [assignedProjects]);

	useEffect(() => {
		if (userId) getUser(userId).then(setUser);
	}, [userId]);

	if (!user) return;

	return (
		<Table>
			<Table.Thead>
				<Table.Tr>
					<Table.Th>Project</Table.Th>
					<Table.Th>Start</Table.Th>
					<Table.Th>End</Table.Th>
					<Table.Th>Billing Type</Table.Th>
					<Table.Th>Rate</Table.Th>
					{user?.user_type === "external_consultant" && (
						<>
							<Table.Th>Consulant Billing Type</Table.Th>
							<Table.Th>Consultant Rate</Table.Th>
						</>
					)}
					<Table.Th />
				</Table.Tr>
			</Table.Thead>
			<Table.Tbody>
				<Table.Tr>
					<Table.Td>
						<ProjectSelector
							selected={newAssignment.project_id}
							whichProjects="all"
							onSelect={projectId => {
								if (projectId)
									setNewAssignment({
										...newAssignment,
										project_id: projectId,
									});
							}}
						/>
					</Table.Td>
					<Table.Td>
						<DateInput
							value={newAssignment.start_date}
							onChange={value => {
								if (value)
									setNewAssignment({
										...newAssignment,
										start_date: value,
										end_date:
											newAssignment.end_date &&
											newAssignment.end_date < value
												? value
												: newAssignment.end_date,
									});
							}}
						/>
					</Table.Td>
					<Table.Td>
						<DateInput
							clearable
							value={newAssignment.end_date}
							minDate={newAssignment.start_date}
							onChange={value => {
								setNewAssignment({
									...newAssignment,
									end_date: value,
								});
							}}
							placeholder="End date"
						/>
					</Table.Td>
					<Table.Td>
						<Select
							width={10}
							value={newAssignment.customer_billing_type}
							onChange={val => {
								if (isBillingType(val))
									setNewAssignment({
										...newAssignment,
										customer_billing_type: val,
									});
							}}
							data={[
								{ value: "hourly", label: "Hourly" },
								{ value: "fixed", label: "Fixed" },
							]}
						/>
					</Table.Td>
					<Table.Td>
						<CurrencyInput
							currency={newAssignment.customer_currency || "SEK"}
							value={newAssignment.customer_rate}
							onAmountChange={val =>
								setNewAssignment({
									...newAssignment,
									customer_rate: +val,
								})
							}
							onCurrencyChange={val =>
								setNewAssignment({
									...newAssignment,
									customer_currency: val,
								})
							}
						/>
					</Table.Td>
					{user?.user_type === "external_consultant" && (
						<>
							<Table.Td>
								<Select
									width={10}
									value={
										newAssignment.consultant_billing_type
									}
									onChange={val => {
										if (isBillingType(val))
											setNewAssignment({
												...newAssignment,
												consultant_billing_type: val,
											});
									}}
									data={[
										{ value: "hourly", label: "Hourly" },
										{ value: "fixed", label: "Fixed" },
									]}
								/>
							</Table.Td>
							<Table.Td>
								<CurrencyInput
									currency={
										newAssignment.consultant_currency ||
										"SEK"
									}
									value={
										newAssignment.consultant_rate != null
											? +newAssignment.consultant_rate
											: 800
									}
									onAmountChange={val =>
										setNewAssignment({
											...newAssignment,
											consultant_rate: +val,
										})
									}
									onCurrencyChange={val =>
										setNewAssignment({
											...newAssignment,
											consultant_currency: val,
										})
									}
								/>
							</Table.Td>
						</>
					)}
					<Table.Td>
						<Button
							disabled={
								typeof newAssignment.project_id !== "string"
							}
							onClick={() => {
								if (
									typeof newAssignment.project_id !== "string"
								)
									return;

								postAssignProjects(
									newAssignment as unknown as InputAssignedProjectsPost,
								).then(() => onAssignmentAdded?.());
							}}
						>
							Add
						</Button>
					</Table.Td>
				</Table.Tr>
				{assignedProjects?.map(ap => {
					const project = getProjectForAssignedProject(ap, projects);
					if (!project) return;

					return (
						<AssignedProjectRow
							onProjectUpdated={() => {
								onAssignmentAdded?.();
							}}
							key={`${ap.user_id}-${ap.project_id}-${ap.start_date}`}
							project={project}
							assignedProject={ap}
							user={user}
						/>
					);
				})}
			</Table.Tbody>
		</Table>
	);
};
