import type { Contact, InputContact, Project } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const getContacts = () => {
	return timeFetch<Array<Contact>>(`${API_BASE_URL}/api/v1/contacts`, {
		method: "GET",
	});
};

export const getContact = (contactId: string) => {
	return timeFetch<Contact>(`${API_BASE_URL}/api/v1/contacts/${contactId}`, {
		method: "GET",
	});
};

export const getContactsByCustomerId = (customerId: string) => {
	return timeFetch<Array<Contact>>(
		`${API_BASE_URL}/api/v1/customers/${customerId}/contacts`,
		{ method: "GET", },
	);
};

export const createContact = (contact: InputContact) => {
	return timeFetch<Contact>(`${API_BASE_URL}/api/v1/contacts`, {
		method: "POST",
		body: JSON.stringify(contact),
	});
};

export const updateContact = (contactId: string, contact: InputContact) => {
	return timeFetch<Contact>(`${API_BASE_URL}/api/v1/contacts/${contactId}`, {
		method: "PUT",
		body: JSON.stringify(contact),
	});
};

export const deleteContact = (objId: string) => {
	return timeFetch<void>(`${API_BASE_URL}/api/v1/contacts/${objId}`, {
		method: "DELETE",
	});
};

export const getProjectByContactId = (contactId: string) => timeFetch<Array<Project>>(
	`${API_BASE_URL}/api/v1/contacts/${contactId}/projects`,
	{ method: "GET", },
)