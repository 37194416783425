import type { JSONResponse } from "../generated";
import { add as addNotification } from "../store/notifications";

// window.location.hostname in dev mode so we can access it if testing on device for example
export const API_BASE_URL =
	import.meta.env.VITE_API_BASE_URL || `http://${window.location.hostname}:3000`;

const getToken = () => localStorage.getItem("token");

export const authHeaders = () => {
	return {
		accept: "application/json",
		authorization: `Bearer ${getToken()}`,
	};
};


type APIErrorData = {
	error: string;
	error_code: number;
	http_code: number;
};

export class APIError extends Error {
	property: APIErrorData;

	constructor(property: APIErrorData) {
		super(property.error);
		this.property = property;
		this.name = "APIError";
	}
}

const handleAPIError = (error: Error) => {
	if (!(error instanceof APIError)) {
		addNotification({
			title: "An error has occured",
			message: `An unknown error has occured, ${error.message}`,
			color: "red",
		});

		throw error;
	};

	addNotification({
		title: `An APIError has occured ${error.property.http_code}`,
		message: `Error code: ${error.property.error_code} ${error.property.error}`,
		color: "red",
	});

	throw error;
}

export const timeFetch = <T>(input: RequestInfo | URL, init?: RequestInit | undefined): Promise<T> => {
	return fetch(input, {
		...init,
		headers: {
			...authHeaders(),
			...init?.headers,
		},
	})
		.then<[Response, JSONResponse<T>]>(res => {
			if (res.status === 204) return [res, {
				error: "",
				error_code: 0,
				response: undefined,
			} as JSONResponse<T>];

			return Promise.all([res, res.json()])
		})
		.then<T>(([res, json]) => {
			if (!res.ok || json.error_code !== 0) throw new APIError({
				error_code: json.error_code,
				error: json.error,
				http_code: res.status,
			});

			return json.response;
		}).catch(handleAPIError);
};