import {
	Box,
	Button,
	Card,
	Grid,
	PasswordInput,
	Select,
	Space,
	Switch,
	TextInput,
	getGradient,
	useMantineTheme,
} from "@mantine/core";
import { matchesField, useForm } from "@mantine/form";
import { IconUsers } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAssignedProjectsAssignedForUser } from "../../api/assigned_projects";
import { createUser, getUser, isUserType, updateUser } from "../../api/users";
import type { AssignedProject, User } from "../../generated";
import { ProjectList } from "./project-list";

const userTypesOptions = [
	{ label: "Internal", value: "internal" },
	{ label: "External Consultant", value: "external_consultant" },
	{ label: "Employee Consultant", value: "employee_consultant" },
];

export const UserForm = () => {
	const theme = useMantineTheme();
	const navigate = useNavigate();
	const { userId } = useParams();
	const [assignedProjects, setAssignedProjects] =
		useState<AssignedProject[]>();

	const form = useForm<
		Omit<User, "id" | "external_id" | "created_at"> & {
			password: string;
			repeatPassword: string;
		}
	>({
		initialValues: {
			name: "",
			email: "",
			password: "",
			repeatPassword: "",
			user_type: "external_consultant",
			active: true,
			admin: false,
		},
		validate: {
			repeatPassword: matchesField("password", "Passwords do not match"),
		},
		validateInputOnChange: ["repeatPassword"],
	});

	useEffect(() => {
		if (!userId) return;
		getUser(userId).then(user => {
			form.setValues({
				name: user.name,
				email: user.email,
				user_type: user.user_type,
				admin: user.admin,
				active: user.active,
			});

			getAssignedProjectsAssignedForUser(userId).then(
				setAssignedProjects,
			);
		});
	}, [userId, form.setValues]);

	const handleUserTypeChange = (userType: string | null) => {
		if (!isUserType(userType)) return;
		form.setValues({
			user_type: userType,
		});
	};

	const handleSave = () => {
		if (userId) {
			updateUser(userId, form.values).then(() => {
				navigate("/users/");
			});
		} else {
			createUser(form.values).then(() => {
				navigate("/users/");
			});
		}
	};

	return (
		<div>
			<Space h="md" />
			<Card shadow="sm" padding="lg" radius="md" withBorder>
				<Card.Section>
					<Box
						style={{
							backgroundImage: getGradient(
								{ from: "orange", to: "gray", deg: 45 },
								theme,
							),
							color: theme.white,
						}}
					>
						<IconUsers width={128} height={128} />
					</Box>
				</Card.Section>

				<Space h="md" />
				<Grid>
					<Grid.Col span={2}>
						<Button component={Link} to={"/users"} color="red">
							Cancel
						</Button>
					</Grid.Col>
					<Grid.Col offset={8} span={2}>
						<Button color="green" onClick={handleSave}>
							Save
						</Button>
					</Grid.Col>

					<Grid.Col span={12}>
						<TextInput
							placeholder="User Name"
							label="User name"
							{...form.getInputProps("name")}
						/>
					</Grid.Col>
					<Grid.Col span={12}>
						<TextInput
							placeholder="email@domain.com"
							label="Email"
							{...form.getInputProps("email")}
						/>
					</Grid.Col>

					{!userId ? (
						<>
							<Grid.Col span={12}>
								<PasswordInput
									placeholder="xxxx"
									label="Password"
									{...form.getInputProps("password")}
								/>
							</Grid.Col>

							<Grid.Col span={12}>
								<PasswordInput
									placeholder="xxxx"
									label="Repeat Password"
									{...form.getInputProps("repeatPassword")}
								/>
							</Grid.Col>
						</>
					) : null}

					<Grid.Col span={6}>
						<Grid.Col span={12}>
							<Switch
								label="Admin"
								checked={form.values.admin}
								{...form.getInputProps("admin")}
							/>
						</Grid.Col>

						<Grid.Col span={12}>
							<Switch
								label="Active"
								checked={form.values.active}
								{...form.getInputProps("active")}
							/>
						</Grid.Col>
					</Grid.Col>

					<Grid.Col span={6}>
						<Select
							value={form.values.user_type}
							placeholder="User type"
							onChange={handleUserTypeChange}
							data={userTypesOptions}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Grid>
							<Grid.Col span={6}>Projects</Grid.Col>
							<Grid.Col span={6} />
						</Grid>
						<ProjectList
							userId={userId}
							assignedProjects={assignedProjects}
							onAssignmentAdded={() => {
								if (userId)
									getAssignedProjectsAssignedForUser(
										userId,
									).then(setAssignedProjects);
							}}
						/>
					</Grid.Col>
				</Grid>
			</Card>
		</div>
	);
};
