import { format } from "date-fns";
import type { OutputReportLock } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const createReportLocks = (dates: string[]) => {
	return timeFetch<Array<OutputReportLock>>(
		`${API_BASE_URL}/api/v1/report_locks`,
		{
			method: "POST",
			body: JSON.stringify(dates),
		},
	);
};

export const deleteReportLocks = (userId: string, dates: string[]) => {
	return timeFetch<boolean>(`${API_BASE_URL}/api/v1/users/${userId}/report_locks`, {
		method: "DELETE",
		body: JSON.stringify(dates),
	});
};

export const getReportLocksForPeriod = (startDate: Date, endDate: Date) => {
	return timeFetch<Array<OutputReportLock>>(
		`${API_BASE_URL}/api/v1/report_locks?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET", },
	);
};

export const getReportLocksForUserInPeriod = (userId: string, startDate: Date, endDate: Date) => {
	return timeFetch<Array<OutputReportLock>>(
		`${API_BASE_URL}/api/v1/report_locks/user/${userId}?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET", },
	);
}
