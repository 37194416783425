import { addMonths, startOfMonth } from "date-fns";
import { ReportLineOverview } from "../../components/report-line-overview";
import { Card, Container } from "@mantine/core";

export const UserDashboard = () => {
	const som = addMonths(startOfMonth(new Date()), -1);
	const eom = new Date();
	return (
		<Container fluid p="xl">
			<Card withBorder radius="md" padding="xl" bg="var(--mantine-color-body)">
				<ReportLineOverview from={som} to={eom} />
			</Card>
		</Container>
	);
};
