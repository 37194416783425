import {
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	startOfMonth,
	startOfWeek,
} from "date-fns";
import type { Period } from "../../../types";

export const getDates = (period: Period, date: Date) => {
	if (period === "month") {
		const x = eachDayOfInterval({
			start: startOfMonth(date),
			end: endOfMonth(date),
		});

		/* Convert this to a proper time.
		   By default date-fns create time 00:00:00 whatever timezone it is, so it would become
		   00:00:00Z02:00 which in utc would be 22:00:00 the day before
		*/
		return x.map(t => {
			const offset = t.getTimezoneOffset() * 60 * 1000;

			return new Date(t.getTime() - offset);
		});
	}
	const x = eachDayOfInterval({
		start: startOfWeek(date, { weekStartsOn: 1 }),
		end: endOfWeek(date, { weekStartsOn: 1 }),
	});

	return x.map(t => {
		const offset = t.getTimezoneOffset() * 60 * 1000;

		return new Date(t.getTime() - offset);
	});
};
