import type { AuthStateType } from ".";

interface PerformLoginSuccessAction {
	type: "APP/LOGIN_SUCCESS";
	payload: string;
}
interface PerformLoginFailedAction {
	type: "APP/LOGIN_FAILED";
	payload: string;
}
interface PerformLoginLoadingAction {
	type: "APP/LOGIN_LOADING";
}
interface PerformLogoutAction {
	type: "APP/LOGOUT_SUCCESS";
}

export const PERFORM_LOGIN_SUCCESS = "APP/LOGIN_SUCCESS";
export const PERFORM_LOGIN_FAILED = "APP/LOGIN_FAILED";
export const PERFORM_LOGIN_LOADING = "APP/LOGIN_LOADING";

export const PERFORM_LOGOUT_SUCCESS = "APP/LOGOUT_SUCCESS";

const token = localStorage.getItem("token");

export const initialAuthState: AuthStateType = {
	isAuthenticated: token ? true : false,
	user: undefined,
	token: token || undefined,
	error: undefined,
	fetching: false,
};

export const doLoginSuccess = (
	payload: PerformLoginSuccessAction["payload"],
): PerformLoginSuccessAction => ({
	type: PERFORM_LOGIN_SUCCESS,
	payload,
});

export const doLoginFailed = (
	payload: PerformLoginFailedAction["payload"],
): PerformLoginFailedAction => ({
	type: PERFORM_LOGIN_FAILED,
	payload,
});

export const doLoginLoading = (): PerformLoginLoadingAction => ({
	type: PERFORM_LOGIN_LOADING,
});

export const doLogoutSuccess = (): PerformLogoutAction => ({
	type: PERFORM_LOGOUT_SUCCESS,
});

export type AuthAction =
	| PerformLoginSuccessAction
	| PerformLoginFailedAction
	| PerformLoginLoadingAction
	| PerformLogoutAction;
