import { useForm } from "@mantine/form";
import type { User } from "../../generated";
import {
	Button,
	Container,
	Grid,
	GridCol,
	Select,
	Switch,
	TextInput,
} from "@mantine/core";
import { isUserType, updateUser } from "../../api/users";
import { Link } from "react-router-dom";
import { useState } from "react";
import { add as addNotification } from "../../store/notifications";

const userTypesOptions = [
	{ label: "Internal", value: "internal" },
	{ label: "External Consultant", value: "external_consultant" },
	{ label: "Employee Consultant", value: "employee_consultant" },
];

type ISettings = {
	user: User;
	onUpdated: () => Promise<void>;
};

export const Settings = ({ user, onUpdated }: ISettings) => {
	const [isSaving, setIsSaving] = useState(false);

	const form = useForm<Omit<User, "id" | "external_id" | "created_at">>({
		initialValues: {
			name: user.name,
			email: user.email,
			user_type: user.user_type,
			active: user.active,
			admin: user.admin,
		},
	});

	const handleUserTypeChange = (userType: string | null) => {
		if (!isUserType(userType)) return;
		form.setValues({
			user_type: userType,
		});
	};

	const handleSave = () => {
		updateUser(user.id, form.values).then(() => {
			setIsSaving(true);
			onUpdated().then(() => {
				addNotification({
					title: "Saved user",
					message: `Successfully saved ${user.name}`,
					lifetime: 3000,
				})
			}).finally(() => setIsSaving(false));
		});
	};

	return (
		<Container pt="xl">
			<Grid>
				<GridCol span={6}>
					<TextInput
						placeholder="User Name"
						label="User name"
						{...form.getInputProps("name")}
					/>
				</GridCol>
				<GridCol span={6}>
					<TextInput
						placeholder="email@domain.com"
						label="Email"
						{...form.getInputProps("email")}
					/>
				</GridCol>

				<GridCol span={6}>
					<Grid.Col span={12}>
						<Switch
							label="Admin"
							checked={form.values.admin}
							{...form.getInputProps("admin")}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Switch
							label="Active"
							checked={form.values.active}
							{...form.getInputProps("active")}
						/>
					</Grid.Col>
				</GridCol>

				<Grid.Col span={6}>
					<Select
						value={form.values.user_type}
						placeholder="User type"
						onChange={handleUserTypeChange}
						data={userTypesOptions}
					/>
				</Grid.Col>

				<Grid.Col span={2}>
					<Button component={Link} to={"/users"} color="red">
						Cancel
					</Button>
				</Grid.Col>
				<Grid.Col offset={8} span={2}>
					<Button loading={isSaving} color="green" onClick={handleSave}>
						Save
					</Button>
				</Grid.Col>
			</Grid>
		</Container>
	);
};
