import type { NationalHolidayProducerFunc } from "../public-holiday";

export const christmasEve: NationalHolidayProducerFunc = (year) => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(11);
	dateObj.setDate(24);

	return dateObj;
};

export const christmasDay: NationalHolidayProducerFunc = (year) => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(11);
	dateObj.setDate(25);

	return dateObj;
};

export const boxingDay: NationalHolidayProducerFunc = (year) => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(11);
	dateObj.setDate(26);

	return dateObj;
};

export const christmasEpiphany: NationalHolidayProducerFunc = (year) => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(0);
	dateObj.setDate(6);

	return dateObj;
};
