import { format, isToday, isWeekend } from "date-fns";
import { Flex, Tooltip } from "@mantine/core";

import classes from "./date-header.module.css";
import clsx from "clsx";
import { IconLock } from "@tabler/icons-react";
import { type DateRange, isLocked } from "./helpers";
import { ViewSwitcher } from "./view-switcher";
import { DateSwitcher } from "./date-switcher";

type Props = {
	dates: DateRange[];
};

const SPECIAL_OCCASION = [
	{
		name: "Julafton",
		emoji: "🎅",
	},
	{
		name: "Påskafton",
		emoji: "🐤",
	},

	{
		name: "Alla helgons dag",
		emoji: "👻",
	},

	{
		name: "Midsommarafton",
		emoji: "🌸",
	},
	{
		name: "Nationaldagen",
		emoji: "🇸🇪",
	},
	{
		name: "Nyårsdagen",
		emoji: "🎆",
	},
];

export const DateHeader = ({ dates }: Props) => (
	<div className={classes.sticky}>
		<Flex gap="lg" justify="center" mb="md">
			<ViewSwitcher />
			<DateSwitcher />
		</Flex>
		<Flex>
			{dates.map(({ date, dayOff, dayOffName }) => (
				<div
					key={date.getTime()}
					data-party
					className={clsx(
						classes.date,
						(dayOff || isWeekend(date)) && classes.weekend,
						isToday(date) && classes.today,
						isLocked(date) && classes.locked,
					)}
				>
					{dayOffName && <div className={classes.specialOccasion}>
						<Tooltip label={dayOffName}>
							<div>
								{
									SPECIAL_OCCASION.find(
										c => c.name === dayOffName,
									)?.emoji
								}
							</div>
						</Tooltip>
					</div>}

					{format(date, "d")}
					<div className={classes.day}>{format(date, "E")}</div>
					{isLocked(date) && (
						<div className={classes.overlay}>
							<IconLock
								size={32}
								stroke={1}
								opacity={0.31}
								color="#fa5252"
							/>
						</div>
					)}
				</div>
			))}
			<div className={classes.total}>Total</div>
		</Flex>
	</div>
);
