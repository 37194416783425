import { useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { createContact } from "../api/contacts";

const smallScreens = ["xs", "sm"];
const largeScreen = ["md", "lg", "xl"];

const emToPixels = (emValue: string) => {
	const emInPixels = Number.parseFloat(
		getComputedStyle(document.documentElement).fontSize,
	);
	const pixels = Number.parseFloat(emValue) * emInPixels;

	return pixels;
};

export const useCurrentViewport = () => {
	const { width } = useViewportSize();
	const theme = useMantineTheme();

	let currentViewport = "xs";

	for (const key in theme.breakpoints) {
		if (width > emToPixels(theme.breakpoints[key])) {
			currentViewport = key;
		}
	}

	return {
		currentViewport: currentViewport,
		smallScreen: smallScreens.includes(currentViewport),
		largeScreen: largeScreen?.includes(currentViewport),
	};
};
