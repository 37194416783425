import type { MantineColor } from "@mantine/core";
import { nanoid } from "nanoid";
import { useStore } from "zustand";
import { createStore } from "zustand/vanilla";

type Notification = {
	id: string;
	title: string;
	message: string;
	icon?: React.ReactNode;
	color?: MantineColor;
	lifetime?: number; // Lifetime in milliseconds
};

interface NotificationState {
	notifications: Array<Notification>;
}

export const notificationStore = createStore<NotificationState>()(() => ({
	notifications: [],
}));

export const add = (notification: Omit<Notification, "id">): string => {
	const id = nanoid();
	notificationStore.setState((state) => ({
		notifications: [
			...state.notifications,
			{
				...notification,
				id,
			},
		],
	}));

	return id;
};

export const remove = (id: string): boolean => {
	const filtered = notificationStore
		.getState()
		.notifications.filter((n) => n.id !== id);

	const hasChanged =
		filtered.length !== notificationStore.getState().notifications.length;

	notificationStore.setState(() => ({
		notifications: filtered,
	}));

	return hasChanged;
};

export function useNotificationStore(): NotificationState;
export function useNotificationStore<T>(
	selector: (state: NotificationState) => T,
): T;
export function useNotificationStore<T>(
	selector?: (state: NotificationState) => T,
) {
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	return useStore(notificationStore, selector!);
}
