import { useNavigate, useParams } from "react-router-dom";
import { getUser, userTypeToReadable } from "../../api/users";
import { useEffect, useState } from "react";
import { Anchor, Container, Tabs } from "@mantine/core";
import type { User as ApiUser, AssignedProject } from "../../generated";
import classes from "./user.module.css";
import { ProjectList } from "./project-list";
import { getAssignedProjectsAssignedForUser } from "../../api/assigned_projects";
import { MonthReport } from "./month-report";
import { Settings } from "./settings";

export const User = () => {
	const navigate = useNavigate();
	const { page } = useParams();

	const [user, setUser] = useState<ApiUser>();
	const { userId } = useParams();
	const [assignedProjects, setAssignedProjects] =
		useState<AssignedProject[]>();

	useEffect(() => {
		if (!userId) return;

		getUser(userId).then(setUser);

		getAssignedProjectsAssignedForUser(userId).then(setAssignedProjects);
	}, [userId]);

	if (!user || !userId) return;

	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>
				<h1>{user.name}</h1>
				<div className={classes.headerListItem}>
					<div>Matrix</div>
					<Anchor href={`matrix:u/@${user.name.split(" ")[0].toLowerCase()}:matrix.virki.se?action=chat`}>
						@{user.name.split(" ")[0].toLowerCase()}:matrix.virki.se
					</Anchor>
				</div>
				<div className={classes.headerListItem}>
					<div>Email</div>
					<Anchor href={`mailto:${user.email}`}>{user.email}</Anchor>
				</div>
				<div className={classes.headerListItem}>
					<div>Type</div>
					<span>{userTypeToReadable(user.user_type)}</span>
				</div>
				<div className={classes.headerListItem}>
					<div>Admin</div>
					<span>{user.admin ? "Yes" : "No"}</span>
				</div>
				<div className={classes.headerListItem}>
					<div>Active</div>
					<span>{user.active ? "Yes" : "No"}</span>
				</div>
			</div>
			<div className={classes.tabWrapper}>
				<Tabs
					value={page || "reports"}
					onChange={value => navigate(`/users/${user.id}/${value}`)}
					classNames={{
						root: classes.root,
						tab: classes.tab,
						panel: classes.panel,
					}}
					variant="outline"
					defaultValue="reports"
					keepMounted={false}
				>
					<Tabs.List justify="center">
						<Tabs.Tab value="reports">Reports</Tabs.Tab>
						<Tabs.Tab value="projects">Projects</Tabs.Tab>
						<Tabs.Tab value="settings">Settings</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value="reports">
						<Container fluid p="xl">
							<MonthReport userId={userId} />
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="projects">
						<Container fluid p="xl">
							<ProjectList
								userId={userId}
								assignedProjects={assignedProjects}
								onAssignmentAdded={() => {
									if (userId)
										getAssignedProjectsAssignedForUser(
											userId,
										).then(setAssignedProjects);
								}}
							/>
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="settings">
						<Settings
							onUpdated={() => getUser(userId).then(setUser)}
							user={user}
						/>
					</Tabs.Panel>
				</Tabs>
			</div>
		</div>
	);
};
